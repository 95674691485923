import { getEnvironment } from "./getEnvironment";

type Tag = {
    label: string;
    value: string;
    tag: string;
};

export const tags = (creativeId: string, creativeSetId: string, userTeam?: string): Tag[] => {
    const env = getEnvironment();
    const randomHex = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
    let tags = [
        {
            label: "Standard JavaScript",
            value: "javascript",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
    window.adn = window.adn || {}; adn.calls = adn.calls || [];
    adn.calls.push(function() {
    adn.request({ 
        creativeSetId: "${creativeSetId}", 
        creativeId: "${creativeId}", 
        targetId: "adn-${randomHex}", 
        targeting: true, 
        network: "${env === "dev" ? "2ac7c" : "b7462"}" 
    });
});
</script>`,
        },
        {
            label: "Adnuntius",
            value: "adnuntius",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
    window.adn = window.adn || {}; adn.calls = adn.calls || [];
    adn.calls.push(function() {
    adn.request({ 
        creativeSetId: "${creativeSetId}", 
        creativeId: "${creativeId}", 
        targetId: "adn-${randomHex}", 
        targeting: true, 
        clickTrackingUrl: "[[adnClickUrlPlaceholder]]",
        network: "${env === "dev" ? "2ac7c" : "b7462"}" 
    });
});
</script>`,
        },
        {
            label: "Standard JavaScript (Cookieless)",
            value: "javascript-cookieless",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
    window.adn = window.adn || {}; adn.calls = adn.calls || [];
    adn.calls.push(function() {
    adn.request({ 
        creativeSetId: "${creativeSetId}", 
        creativeId: "${creativeId}", 
        targetId: "adn-${randomHex}", 
        useCookies: false,
        targeting: true, 
        network: "${env === "dev" ? "2ac7c" : "b7462"}" 
    });
});
</script>`,
        },
        {
            label: "Adform",
            value: "adform",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
    window.adn = window.adn || {};
    adn.calls = adn.calls || [];
    adn.calls.push(function () {
        adn.request({
            creativeSetId: "${creativeSetId}",
            creativeId: "${creativeId}",
            targetId: "adn-${randomHex}",
            targeting: true,
            network: "${env === "dev" ? "2ac7c" : "b7462"}",
            clickTrackingUrl: "%%c1;cpdir=",
        });
    });
</script>`,
        },
        {
            label: "Xandr",
            value: "xandr",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
    window.adn = window.adn || {};
    adn.calls = adn.calls || [];
    adn.calls.push(function () {
        adn.request({
            env: "limited",
            creativeSetId: "${creativeSetId}",
            creativeId: "${creativeId}",
            targetId: "adn-${randomHex}",
            network: "${env === "dev" ? "2ac7c" : "b7462"}",
            clickTrackingUrl: "$\{CLICK_URL}",
            useCookies: false,
            gdpr: "$\{GDPR}",
            gdpr_consent: "$\{GDPR_CONSENT_855}",
        });
    });
</script>`,
        },
        {
            label: "Google Ad Manager",
            value: "gam",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
window.adn = window.adn || {}; adn.calls = adn.calls || [];
adn.calls.push(function() {
    adn.request({ 
        creativeSetId: "${creativeSetId}", 
        creativeId: "${creativeId}", 
        targetId: "adn-${randomHex}", 
        env: "limited",
        clickTrackingUrl: "%%CLICK_URL_ESC%%", 
        targeting: true, 
        network: "${env === "dev" ? "2ac7c" : "b7462"}"  
    });
});
</script>`,
        },
        {
            label: "Google DV360",
            value: "dv360",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
window.adn = window.adn || {}; adn.calls = adn.calls || [];
adn.calls.push(function() {
    adn.request({ 
        creativeSetId: "${creativeSetId}", 
        creativeId: "${creativeId}", 
        targetId: "adn-${randomHex}", 
        env: "limited",
        clickTrackingUrl: "$\{CLICK_URL_ENC}", 
        targeting: true, 
        network: "${env === "dev" ? "2ac7c" : "b7462"}"  
    });
});
</script>`,
        },
        {
            label: "Google DV360 (CSV)",
            value: "dv360-csv",
            tag: `"<div id=""adn-${randomHex}""></div><script src=""https://cdn.adnuntius.com/adn.js"" async></script><script>window.adn = window.adn || {}; adn.calls = adn.calls || [];adn.calls.push(function() {adn.request({ creativeSetId: ""${creativeSetId}"", creativeId: ""${creativeId}"", targetId: ""adn-${randomHex}"", env: ""limited"",clickTrackingUrl: ""$\{CLICK_URL_ENC}"", targeting: true, network: ""${
                env === "dev" ? "2ac7c" : "b7462"
            }""  });});</script>"`,
        },
        {
            label: "Schibsted Norway",
            value: "schibsted-no",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
window.adn = window.adn || {}; adn.calls = adn.calls || [];
adn.calls.push(function () {
    adn.request({
        env: "limited",
        creativeSetId: "${creativeSetId}", 
        targetId: "adn-${randomHex}",
        creativeId: "${creativeId}",
        clickTrackingUrl: "\${CLICK_URL}",
        useCookies: false,
        network: "${env === "dev" ? "2ac7c" : "b7462"}" 
    });
});
</script>`,
        },
        {
            label: "Google CM export",
            value: "google-cm-export",
            tag: `<div id="adn-${randomHex}"></div>
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
window.adn = window.adn || {}; adn.calls = adn.calls || [];
adn.calls.push(function () {
    adn.request({
        targeting: true,
        env: "limited",
        creativeSetId: "${creativeSetId}", 
        targetId: "adn-${randomHex}",
        creativeId: "${creativeId}",
        clickTrackingUrl: "%c%u",
        useCookies: false,
        kv: {
            source: ['cm360']
        },
        network: "${env === "dev" ? "2ac7c" : "b7462"}",
        consentString: "$\{GDPR_CONSENT_855}",
        gdpr: "$\{GDPR}"
    });
});
</script>`,
        },
        {
            label: "Google CM (ZIP)",
            value: "gcm-zip",
            tag: ``,
        },
    ];
    if (userTeam === "lrlcdyk9mhvtt6wz") {
        // If user belongs to the Kobler team
        tags = tags.filter((t) => t.value === "javascript-cookieless");
    }
    return tags;
};

export const gamTag = (
    creativeId: string,
    creativeSetId: string,
    width: number,
    height: number,
    userTeam?: string,
    isFullScreen?: string | undefined
): Tag => {
    const env = getEnvironment();
    const randomHex = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
    const impressionTracker = '<img src="%i" />';
    return {
        label: "Google CM export",
        value: "google-cm-export",
        tag: `<div id="adn-${randomHex}"></div>
        ${isFullScreen === "true" ? impressionTracker : ""}
<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
window.adn = window.adn || {}; adn.calls = adn.calls || [];
adn.calls.push(function () {
adn.request({
    targeting: true,
    env: "limited",
    creativeSetId: "${creativeSetId}", 
    targetId: "adn-${randomHex}",
    dimensions: [[${width}, ${height}]],
    clickTrackingUrl: "%c%u",
    useCookies: false,
    kv: {
        source: ['cm360']
    },
    network: "${env === "dev" ? "2ac7c" : "b7462"}",
    consentString: "$\{GDPR_CONSENT_855}",
    gdpr: "$\{GDPR}"
});
});
</script>`,
    };
};
