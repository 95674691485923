import { useContext, useEffect, useState } from "react";
import { useUser } from "../../../contexts/useUser";
import { CreateAdvertiser } from "./CreateAdvertiser";
import { CreateCreativeSet } from "./CreateCreativeSet";
import { CreateFolio } from "./CreateFolio";

type TypeName = "folio" | "advertiser" | "creativeSet";

interface CreateProps {
    visible: boolean;
    setVisible: any;
    advertiserId?: string;
    type: TypeName;
    onCreateFolio?: any;
    onCreateAdvertiser?: any;
    onCreateCreativeSet?: any;
    user?: any;
    folioId?: string;
    advertiser?: any;
}

export const Create = (props: CreateProps) => {
    const [team, setTeam] = useState<any>();
    const user = useContext(useUser);

    const handleCloseClick = (e: any) => {
        if (e.target.classList.contains("manage-create-container")) {
            props.setVisible();
        }
    };

    useEffect(() => {
        if (props.advertiser) {
            // if the user belongs to the advertisers ownerTeam, use that ID
            let foundTeam = user.teams.filter((team: any) => team.id === props.advertiser.ownerTeam.id);
            if (foundTeam[0]) {
                setTeam(foundTeam[0].id);
            } else {
                // if no match was found for ownerTeam, look through all partner teams
                props.advertiser.teams.forEach((advTeam: any) => {
                    let found = user.teams.filter((team: any) => team.id === advTeam.id);
                    if (found[0]) setTeam(found[0]);
                });
            }
        }
    }, [props.advertiser]);

    const typeConversion: { [key: string]: string } = {
        folio: "folio",
        advertiser: "advertiser",
        creativeSet: "creative set",
    };
    console.log("ADV", props.advertiser);

    return (
        <>
            {props.visible && (
                <div className="manage-create-container" onClick={handleCloseClick}>
                    <div className="manage-create">
                        <h2>
                            <span>Create {typeConversion[props.type]} </span>
                        </h2>
                        {props.type === "folio" && props.advertiserId && props.advertiser && (
                            <CreateFolio
                                user={props.user}
                                advertiserId={props.advertiserId}
                                onCreateFolio={props.onCreateFolio}
                                setVisible={props.setVisible}
                                teamId={team.id}
                            />
                        )}
                        {props.type === "advertiser" && (
                            <CreateAdvertiser
                                user={props.user}
                                onCreateAdvertiser={props.onCreateAdvertiser}
                                setVisible={props.setVisible}
                            />
                        )}
                        {props.type === "creativeSet" && props.folioId && (
                            <CreateCreativeSet
                                user={props.user}
                                onCreateCreativeSet={props.onCreateCreativeSet}
                                setVisible={props.setVisible}
                                folioId={props.folioId}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
